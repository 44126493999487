import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLottie } from "lottie-react";
import { useAuth } from "../AuthContext"; // Update this path if necessary


const FrameComponent = ({ className = "" }) => {
  const [animationData, setAnimationData] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/exercise.json`)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error("Error loading animation:", error));
  }, []);

  const options = {
    animationData,
    loop: true,
    autoplay: true,
  };

  const { View, error } = useLottie(options, { width: 171, height: 171 });

  const launchGame = (gameType) => {
    let gameUrl;
    let gameId;
    switch (gameType) {
      case "apples":
        gameUrl = "/Games/Apple/game_applepicking.html";
        gameId = 1;
        break;
      case "pizzas":
        gameUrl = "/old/pizza_main.html";
        gameId = 2;
        break;
      case "garden":
        gameUrl = "/old/plant_main.html";
        gameId = 3;
        break;
      default:
        console.error("Unknown game type");
        return;
    }
    localStorage.setItem('currentGameId', gameId);
    if (user) {
      localStorage.setItem('userData', JSON.stringify(user));
    } else {
      console.warn('No user data available');
    }
    window.location.href = gameUrl;
  };

  const handleInteraction = (gameType, event) => {
    event.preventDefault();
    launchGame(gameType);
  };

  return (
      <div
          className={`flex-1 flex flex-col items-start justify-start gap-[32px] max-w-full text-left text-lg text-gray-100 font-montserrat mq675:gap-[16px] mq825:min-w-full ${className}`}
      >
        <div className="self-stretch rounded-mini box-border flex flex-row items-center justify-between pt-3 pb-2 pr-[21px] pl-[29px] gap-[24px] max-w-full border-[1px] border-solid border-gainsboro-100 mq825:flex-wrap">
          <div className="flex-1 flex flex-col items-start justify-center pt-[5px] px-0 pb-0 box-border max-w-full mq675:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-center gap-[5px] max-w-full">
              <div className="w-[606px] h-[83px] relative max-w-full">
                <img
                    className="absolute top-[0px] left-[0px] w-[211px] h-[39.3px] object-contain z-[2]"
                    loading="lazy"
                    alt=""
                    src="/blacklogo-transbg-2@2x.png"
                />
                <div className="absolute top-[45px] left-[0px] flex flex-row items-center justify-start w-full">
                  <div className="relative leading-[22px] z-[1]">
                    Hello, don't forget to take care of your health!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start py-0 pr-0 pl-px box-border max-w-full text-9xl">
                <div className="h-11 flex-1 relative tracking-[0.01em] inline-block max-w-full z-[1] mq450:text-3xl">
                  {`Choose your activity below. Enjoy one or try them all!`}
                </div>
              </div>
            </div>
          </div>
          {!animationData ? (
              <div>Loading animation...</div>
          ) : error ? (
              <div>Error loading animation: {error.message}</div>
          ) : (
              <div className="z-[1]">{View}</div>
          )}
        </div>
        <div className="w-[959px] overflow-x-auto flex flex-row items-start justify-start gap-[16px] max-w-full text-xl text-text-main-text">
          <div
              onClick={(e) => handleInteraction("apples", e)}
              onTouchStart={(e) => handleInteraction("apples", e)}
              className="w-[309px] rounded-xl box-border shrink-0 flex flex-row items-start justify-start p-2.5 gap-[21.5px] border-[1.5px] border-solid border-border-and-seperator-border-seperator-light cursor-pointer"
          >
            <div className="flex-1 flex flex-col items-center justify-center pt-[21px] px-0 pb-0">
              <b className="self-stretch h-[72px] relative leading-[120%] flex items-center shrink-0 mq450:text-base mq450:leading-[19px]">
              <span className="[line-break:anywhere]">
                <p className="m-0">Pick</p>
                <p className="m-0">Apples</p>
              </span>
              </b>
            </div>
            <div className="h-[114px] flex-1 relative rounded-xl bg-aquamarine overflow-hidden">
              <img
                  className="absolute h-[calc(100%_-_3px)] top-[2px] bottom-[1px] left-[0.5px] max-h-full w-[111px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/appletree-1@2x.png"
              />
            </div>
          </div>
          <div
              onClick={(e) => handleInteraction("pizzas", e)}
              onTouchStart={(e) => handleInteraction("pizzas", e)}
              className="w-[309px] rounded-xl box-border shrink-0 flex flex-row items-start justify-start p-2.5 gap-[21.5px] border-[1.5px] border-solid border-border-and-seperator-border-seperator-light cursor-pointer"
          >
            <div className="flex-1 flex flex-col items-center justify-center pt-[21px] px-0 pb-0">
              <b className="self-stretch h-[72px] relative leading-[120%] flex items-center shrink-0 mq450:text-base mq450:leading-[19px]">
              <span className="[line-break:anywhere]">
                <p className="m-0">Roll</p>
                <p className="m-0">Pizzas</p>
              </span>
              </b>
            </div>
            <div className="h-[114px] flex-1 relative rounded-xl bg-hotpink overflow-hidden">
              <img
                  className="absolute h-[calc(100%_-_24px)] top-[12px] bottom-[12px] left-[20.5px] max-h-full w-[90px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/rollingpin-1@2x.png"
              />
            </div>
          </div>
          <div
              onClick={(e) => handleInteraction("garden", e)}
              onTouchStart={(e) => handleInteraction("garden", e)}
              className="w-[309px] rounded-xl box-border shrink-0 flex flex-row items-start justify-start p-2.5 gap-[21.5px] border-[1.5px] border-solid border-border-and-seperator-border-seperator-light cursor-pointer"
          >
            <div className="flex-1 flex flex-col items-center justify-center pt-[21px] px-0 pb-0">
              <b className="self-stretch h-[72px] relative leading-[120%] flex items-center shrink-0 mq450:text-base mq450:leading-[19px]">
              <span>
                <p className="m-0">Plant</p>
                <p className="m-0">a garden</p>
              </span>
              </b>
            </div>
            <div className="h-[114px] flex-1 relative rounded-xl bg-khaki overflow-hidden">
              <img
                  className="absolute w-[calc(100%_-_18.5px)] top-[0px] right-[10px] left-[8.5px] max-w-full overflow-hidden h-[113px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/gardening-1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
