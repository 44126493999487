import { FilesetResolver, PoseLandmarker } from '@mediapipe/tasks-vision';

let poseLandmarker = null;
let lastPoseTime = 0;
let repetitionCount = 0;
let previousWristHeight = 0;
let hasSpawnedApple = false;
let isInDownPosition = true;
let isMovingUp = false;
let onRepetitionCallback = null;
const THRESHOLD_HEAD = 0.15;
const THRESHOLD_SHOULDER = 0.15;
const MOVEMENT_THRESHOLD = 0.02;
const COOLDOWN = 1000;

export async function initPoseDetection() {
    const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
    );

    poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
            modelAssetPath: "https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task",
            delegate: "GPU"
        },
        runningMode: "VIDEO",
        numPoses: 1
    });
}

export async function detectPose(video, timestamp) {
    if (!poseLandmarker) return null;
    const results = await poseLandmarker.detectForVideo(video, timestamp);
    return analyzePoseForHandRaise(results);
}

export function setOnRepetitionCallback(callback) {
    onRepetitionCallback = callback;
}

function analyzePoseForHandRaise(results) {
    if (!results?.landmarks?.[0]) return false;

    const currentTime = Date.now();
    if (currentTime - lastPoseTime < COOLDOWN) return false;

    const pose = results.landmarks[0];
    const nose = pose[0];
    const leftWrist = pose[15];
    const rightWrist = pose[16];
    const leftShoulder = pose[11];
    const rightShoulder = pose[12];

    const activeWrist = leftWrist.y < rightWrist.y ? leftWrist : rightWrist;
    const activeShoulder = leftWrist.y < rightWrist.y ? leftShoulder : rightShoulder;

    const heightDifference = previousWristHeight - activeWrist.y;
    isMovingUp = heightDifference < -MOVEMENT_THRESHOLD;
    previousWristHeight = activeWrist.y;

    const isAboveHead = activeWrist.y < (nose.y - THRESHOLD_HEAD) &&
        Math.abs(activeWrist.x - activeShoulder.x) < 0.15 &&
        activeWrist.z < activeShoulder.z;

    const isBelowShoulder = activeWrist.y > (activeShoulder.y + THRESHOLD_SHOULDER);

    if (isBelowShoulder && !isInDownPosition) {
        isInDownPosition = true;
        hasSpawnedApple = false;
        console.log('Reset: Hands returned to down position');
        return false;
    }

    if (isAboveHead && isInDownPosition && !hasSpawnedApple && isMovingUp) {
        isInDownPosition = false;
        hasSpawnedApple = true;
        lastPoseTime = currentTime;
        repetitionCount++;
        console.log(`COMPLETE REP ${repetitionCount} - Apple spawned`);
        if (onRepetitionCallback) {
            onRepetitionCallback(repetitionCount);
        }
        return true;
    }

    return false;
}

export function getRepetitionCount() {
    return repetitionCount;
}

export default initPoseDetection;