import React from 'react';
import { useRef } from 'react';
import { animate } from './Apple';
import {Howl, Howler} from "howler"

var confirm_callback
var timer_string
var quit_game_callback
var unpause_game_cb
var pause_game_cb

//helper function to animations
function animate_div(div_id, to_remove, to_add = "x", delay){
    window.setTimeout(()=>{
        if(to_remove != "none"){
            document.getElementById(div_id).classList.remove(to_remove)
        }
        document.getElementById(div_id).classList.add(to_add)
    }, delay)
}

var sfx_start = new Howl({
    src: ['Retro9.wav']
})

var sfx_start_2 = new Howl({
    src: ['Retro10.wav']
})

var sfx_dot = new Howl({
    src: ['Modern2.wav']
})

var sfx_pause = new Howl({
    src: ['Minimalist1.wav']
})

var sfx_complete = new Howl({
    src: ['Retro7.wav']
})

function sfx_button_click(){
    sfx_start.play();
}

/*
        Initialization
*/
export function gui_set_callbacks(cb_confirm_callback, cb_quit_callback, cb_pause, cb_unpause){
    confirm_callback = cb_confirm_callback
    quit_game_callback = cb_quit_callback

    unpause_game_cb = cb_unpause
    pause_game_cb = cb_pause

    animate_div("intro-container-top", "hidden", "fade-in", 1000)
    animate_div("intro-container-bot", "hidden", "fade-in", 2000)
    animate_div("intro-button-container", "hidden", "fade-in", 3000)


    const sfx_chain = window.setTimeout(()=>{
        console.log("play")
        sfx_dot.play()
        window.setTimeout(()=>{
            sfx_dot.play()
            window.setTimeout(()=>{
                sfx_dot.play()
            }, 1000)
        }, 1000)
    }, 1000)
}

/*
        On Confirm Instructions
*/
export function confirm_instructions(){     
        sfx_button_click()

        animate_div("intro-game-logo-container","none","slide-out-L", 0)
        animate_div("intro-instructions-container","none","slide-out-L", 0)
        animate_div("intro-game-text-container","none","slide-out-R", 0)
        animate_div("intro-button-container","none","slide-out-R", 0)
        animate_div("intro-container","none",  "fade-out", 2000)

        window.setTimeout(()=>{
            sfx_start_2.play()

            if(confirm_callback){
                confirm_callback()
                animate_div("game-hud", "hidden", "fade-in", 0)

            }
        }, 3000)

}

function quit_game(){
    if(quit_game_callback){
        quit_game_callback()
    }
}

function pause_game(){
    sfx_pause.play()

    animate_div("game-hud", "none", "fade-out", 0)
    animate_div("pause-gui","hidden","fade-in", 500)

    if(pause_game_cb) pause_game_cb()
}

function unpause_game(){
    sfx_pause.play()

    animate_div("game-hud", "fade-out", "fade-in", 500)
    animate_div("pause-gui","fade-in","fade-out", 0)
    animate_div("pause-gui","fade-out", "hidden", 1000)

    if(unpause_game_cb) unpause_game_cb()
}


export function show_gameover(){
    window.setTimeout(()=>{
        sfx_complete.play()
    }, 1000)
    pause_game()
    animate_div("pause-quit-button", "none", "hidden")
    document.getElementById("pause-continue-button-text").innerText = "Finish"
    document.getElementById("pause-continue-button").onclick = quit_game
    document.getElementById("pause-info-completed").innerText = "Completed"
}

/*
        On Gameover
*/
export function gui_gameover(max_rep, duration){
    return
    
    timer_string = secondsToMinutesSeconds(duration)

    document.getElementById("gameover").classList.add("fade-in")

    document.getElementById("main").classList.remove("fade-in")
    document.getElementById("main").classList.add("fade-out")
    document.getElementById("gameover-bubble").classList.remove("hidden")
    document.getElementById("gameover-bubble").classList.add("fade-in")

    document.getElementById("gameover-return").classList.remove("hidden")

    document.getElementById("gameover-return").classList.add("fade-in-delayed")
    
    document.getElementById("stats").innerText = `Completed ${max_rep} reps.`
    document.getElementById("duration").innerText = `Duration: ${timer_string} min`

}

/*
        TIMER STRING UPDATE CALLED BY MAIN GAME WINDOW
*/
export function gui_update_timer(seconds){
    timer_string = secondsToMinutesSeconds(seconds)
    document.getElementById("duration-text").innerText = timer_string;
}

function secondsToMinutesSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');
  
    return `${minutesStr}:${secondsStr}`;
  }

/*
       INSTRUCTIONS LAYOUT
*/
const GameIntroLayout = ()=>{
    return(
        <div id ="intro-container" className = "border">
            <div id = "intro-container-top" className = "border hidden">
                <div id ="intro-game-logo-container" className = "border">
                    
                    <img id = "intro-game-logo-img" src = "apple_pick_logo.svg"></img>
                </div>
                <div id ="intro-game-text-container" className = "border">
                    <p>
                    Raise one arm at a time to collect apples before the timer runs out!
                    </p>
                </div>
            </div>
            <div id = "intro-container-bot" className = "border hidden">
                <div id ="intro-instructions-container" className = "border">

                    <object id = "intro-instructions-animation"type="image/svg+xml" data ="instructions_animated_skinny.svg"></object>

                </div>

                <div id ="intro-button-container" className = "border hidden">
                    <button id = "intro-button" onClick={confirm_instructions}> 
                        <div id ="intro-button-contents">
                            <div id="intro-button-icon-play-container"><img id = "intro-button-icon-play" src="playbutton_red.svg"/></div>
                            <div><p>Play</p></div>
                        </div>
                    </button>
                </div>

            </div>
        
            
        </div> )
}

/*
        MAIN GAME UI LAYOUT
*/
const MainGUILayout = ()=>{


    return(
        <div id = "game-hud" className = "hidden">

            <div id ="game-hud-left" className='border'>

                <div className = "game-hud-item-container border">
                    <button className="cancel border" onClick = {pause_game}>
                        
                    </button>
                </div>

                <div className = "game-hud-item-container border shift-left">
                        <div className="game-ui assignment-bubble l label-back border">
                            <div className = "game-hud-label-contents border">
                            <img id = "timer-icon-img" src = "timer_thin.svg"></img>
                                <p id = "duration-text" className = "game-info" >
                                ~    
                                </p>
                            </div>

                        </div>
                </div>

            </div>

            <div id ="game-hud-right" className='border'>
            <div className = "game-hud-item-container border shift-left">
                <div class="game-ui assignment-bubble r label-back border">
                    <div className = "game-hud-label-contents r-c border">
                        <img id = "timer-icon-img" src = "apple.svg"></img>
                        <p id = "goal-text" className = "game-info">
                        00x
                        </p>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
    )
}

/*
    Pause screen layout
*/
export function setSummaryText(reps, timer){
    var timer_s = secondsToMinutesSeconds(timer)
    document.getElementById("pause-info-reps-text").innerText = `${reps}`
    document.getElementById("pause-info-time-text").innerText = `${timer_s}`
}

const PauseGUILayout = ()=>{
    return(
    <div id = "pause-gui" className = "hidden">
        <div id = "pause-panel-container">
            <div id = "pause-panel-container-top">
                <div id = "pause-game-graphic-container">

                    <div id = "pause-game-graphic">
                    <img src = "apple_pick_logo.svg"></img>
                    </div>

                </div>
                <div id = "pause-info-container">

                    <div id ="pause-info-panel" className = "label-info-back game-ui">
                        <div id = "pause-info-panel-text">
                            <p id = "pause-info-h">Summary</p>


                                <div className = "pause-info-row">
                                    <p class = "pause-info-label">Total Reps</p>
                                    <p id = "pause-info-reps-text">00</p>
                                </div>

                                <div className = "pause-info-row">
                                    <p class = "pause-info-label">Time</p>
                                    <p id = "pause-info-time-text">00</p>
                                </div>

                                <div className = "pause-info-row">
                                        <p id = "pause-info-completed" class = "pause-info-label">Incomplete</p>
                                </div>

                            </div>


                        
                    </div>

                </div>

            </div>


            <div id = "pause-panel-container-bot">
                <div id = "pause-quit-container">
                    <button id = "pause-quit-button" className = "cancel" onClick = {quit_game}></button>
                </div>
                <div id = "pause-continue-container">
                    <button id = "pause-continue-button" onClick = {unpause_game}>
                        <div id = "pause-continue-contents">
                            <div id="intro-button-icon-play-container">
                                <img id = "intro-button-icon-play" src="playbutton_red.svg"/>
                            </div>
                            <div><p id ="pause-continue-button-text">Resume</p></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    )
}

/*
        GAME OVER UI LAYOUT
*/
const GameoverLayout = ()=>{
    return(
        <div id ="gameover">
            <div id = "gameover-bubble"
            class = "hidden game-ui">
            <img id = "gameover-doc-img" src="doc_clip4.svg"></img>
                        <p id = "gameover-text" >
                            Exercise complete!
                        </p>
                        <div id ="duration"></div>
                        <div id ="stats"></div>
            </div>
            <button class="game-ui hidden" id = "gameover-return" onClick={confirm_instructions}> 
            Return
            </button>
        </div>
    )
}

/*
        EXPORT
*/

export const GameHUD = () =>{

    return(
        <div id="main">

        <style>
        @import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100..900&display=swap');
        </style>

        
        <GameIntroLayout />
        <PauseGUILayout />
        <MainGUILayout />
        

        </div>
    )
}

export default GameHUD;