// camera.js
import { FilesetResolver, ImageSegmenter } from '@mediapipe/tasks-vision';
import { Camera } from '@mediapipe/camera_utils';
import { initPoseDetection, detectPose } from './PoseDetection';
import { spawn_apple } from './Apple';
import SegmentationProcessor from './Segmentation';

let canvas, ctx, video, imageSegmenter, stream;
const segProcessor = new SegmentationProcessor();
const WIDTH = 1920, HEIGHT = 1080;

export async function init(canvasId = "camera_canvas") {
    try {
        // Initialize the canvas where the final composited output will be rendered.
        ({ canvas, ctx } = initializeCanvas(canvasId));
        video = document.createElement('video');
        video.autoplay = true;
        video.muted = true;

        // Load the Mediapipe vision tasks and the segmentation model.
        const vision = await FilesetResolver.forVisionTasks(
            "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
        );

        imageSegmenter = await ImageSegmenter.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath:
                    "https://storage.googleapis.com/mediapipe-tasks/image_segmenter/selfie_segmentation_landscape.tflite",
                delegate: "GPU"
            },
            outputCategoryMask: true,
            runningMode: "VIDEO"
        });

        // Initialize pose detection if needed.
        await initPoseDetection();

        // Request the video stream from the user’s webcam.
        stream = await navigator.mediaDevices.getUserMedia({
            video: {
                width: { ideal: WIDTH },
                height: { ideal: HEIGHT },
                frameRate: { ideal: 60, max: 60 }
            }
        });

        video.srcObject = stream;
        await new Promise((resolve, reject) => {
            video.onloadedmetadata = resolve;
            video.onerror = reject;
        });

        // Set up the Mediapipe Camera which calls the segmentation processor on every frame.
        const camera = new Camera(video, {
            onFrame: async () => {
                // Reinitialize the canvas context if needed.
                if (!ctx) ({ ctx } = initializeCanvas(canvasId));
                const timestamp = performance.now();

                // Get the segmentation results and process the mask.
                const segmentationResults = await imageSegmenter.segmentForVideo(video, timestamp);
                await segProcessor.processSegmentation(segmentationResults, ctx, video, WIDTH, HEIGHT);

                // Optional: detect a hand pose to trigger additional actions (like spawning an apple)
                const isHandRaised = await detectPose(video, timestamp);
                if (isHandRaised) {
                    spawn_apple();
                }
            },
            width: WIDTH,
            height: HEIGHT
        });

        await camera.start();
    } catch (error) {
        console.error("Error in initialization:", error);
        throw error;
    }
}

// Helper function to initialize the canvas element.
function initializeCanvas(canvasId) {
    const canvas = document.getElementById(canvasId);
    if (!canvas) throw new Error("Canvas element not found");

    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    const ctx = canvas.getContext("2d", {
        willReadFrequently: true,
        alpha: true
    });

    return { canvas, ctx };
}

// Cleanly stop the video stream when needed.
export async function close_stream() {
    if (stream) {
        stream.getTracks().forEach((track) => {
            track.stop();
            stream.removeTrack(track);
        });
        video.srcObject = null;
    }
}
