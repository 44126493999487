import * as THREE from "three"
import { bumpMap, diffuseColor, emissive, roughness } from "three/tsl";
import { FBXLoader } from "three/examples/jsm/Addons.js";
import { alpha } from "@mui/material";
import * as CANNON from "cannon/build/cannon.js"
import { Timer } from 'three/addons/misc/Timer.js';

const timer = new Timer()

//apple buffer
const max_apples = 30
var apple_preload = []
var curr_apple = 0

var apple_array = []
var apple_physicsbodies = []


var delta_time = 0

let video, video_texture, shaderMaterial;
let scene_camera
let video_plane
let physics_world
let scene_data

let apple_body
let apple_mesh

export function spawn_apple(){
    let x = generate_apple();
    let apple_mesh = x[0]
    let apple_body = x[1]

    physics_world.add(apple_body)
    scene_data.scene.add(apple_mesh)
    apple_physicsbodies.push(apple_body)
    apple_array.push(apple_mesh)

}


//Used to preload the apples and prevent lag on first apple load
function spawn_test_apple(){
    let x = generate_apple();
    let apple_mesh = x[0]
    let apple_body = x[1]

    apple_body.position = new CANNON.Vec3(
        0,
        -1,
        0
    )
    

    physics_world.add(apple_body)
    scene_data.scene.add(apple_mesh)
    apple_physicsbodies.push(apple_body)

    window.setTimeout(()=>{
        apple_mesh.visible = false;
        physics_world.removeBody(apple_body)
    }, 10)

    apple_array.push(apple_mesh)
}

function generate_apple(){
     //create cloned apple
     var clone = apple_mesh.clone()
     clone.position.y = -.1
     var centered_group = new THREE.Group()
     
     //center the model's origin with a group
     centered_group.add(clone)
     centered_group.position.x = (Math.random()-0.5)
     centered_group.position.y = 0.5
 
     //create physics
     const apple_radius = 0.125
     apple_body = new CANNON.Body({
         mass: 5, 
         position: new CANNON.Vec3(
             (Math.random()-0.5),
             1,
             (Math.random()-0.5)/2
         ),
         shape: new CANNON.Sphere(apple_radius)
     })

     return [centered_group, apple_body]
}

function init_apples(){

    //static scale to normalize model size
    const apple_scale = 0.05
    
    const fbx_loader = new FBXLoader()
    const tex_loader = new THREE.TextureLoader()
    console.log("init apple")

    //generate apple pre-loaded buffer
    function preload_apples(){
        spawn_test_apple()
    }


    //load textures
    const apple_texture = tex_loader.load('/Apple/Textures/2k/2k_Apple_diffuse.png');
    apple_texture.colorSpace = THREE.SRGBColorSpace
    const apple_material = new THREE.MeshStandardMaterial({
        map: apple_texture,
        normal: tex_loader.load('/Apple/Textures/4k/4k_Apple_normal.png'),
        bumpMap: tex_loader.load('/Apple/Textures/4k/4k_Apple_roughness.png')})

    //load model
    fbx_loader.load('/Apple/Mesh/Apple_Mesh.fbx', function(fbx){
        //traverse 3D scene tree for apple node
        fbx.traverse(function(node){
                if(node.isMesh){
                    node.scale.set(apple_scale, apple_scale, apple_scale)
                    node.material = apple_material
                    apple_mesh = node;
                    preload_apples()
                }

            })
        })
}

function init_physics(){
    physics_world = new CANNON.World();
    physics_world.gravity.set(0,-3,0)
    create_box()
    //physics_world.add(apple_box)
}

function create_box(){
    // ground plane
    var groundShape = new CANNON.Plane();
    var groundBody = new CANNON.Body({ mass: 0});
    groundBody.addShape(groundShape);
    physics_world.add(groundBody);

    // plane -x
    var planeShapeXmin = new CANNON.Plane();
    var planeXmin = new CANNON.Body({ mass: 0});
    planeXmin.addShape(planeShapeXmin);
    planeXmin.quaternion.setFromAxisAngle(new CANNON.Vec3(0,1,0),Math.PI/2);
    planeXmin.position.set(-1,0,0);
    physics_world.add(planeXmin);

    // Plane +x
    var planeShapeXmax = new CANNON.Plane();
    var planeXmax = new CANNON.Body({ mass: 0});
    planeXmax.addShape(planeShapeXmax);
    planeXmax.quaternion.setFromAxisAngle(new CANNON.Vec3(0,1,0),-Math.PI/2);
    planeXmax.position.set(1,0,0);
    physics_world.add(planeXmax);

    // Plane -y
    var planeShapeYmin = new CANNON.Plane();
    var planeYmin = new CANNON.Body({ mass: 0 });
    planeYmin.addShape(planeShapeYmin);
    planeYmin.quaternion.setFromAxisAngle(new CANNON.Vec3(1,0,0),-Math.PI/2);
    planeYmin.position.set(0,-0.5,0);
    physics_world.add(planeYmin);

    // Plane +y
    var planeShapeYmax = new CANNON.Plane();
    var planeYmax = new CANNON.Body({ mass: 0});
    planeYmax.addShape(planeShapeYmax);
    planeYmax.quaternion.setFromAxisAngle(new CANNON.Vec3(1,0,0),Math.PI/2);
    planeYmax.position.set(0,5,0);
    physics_world.add(planeYmax)
}

function init_scene(window){
    const canvas = document.getElementById("gamecanvas") 

    const scene = new THREE.Scene();

    const textureLoader = new THREE.TextureLoader();
    const bgTexture = textureLoader.load('back_new.jpeg');
    const fgTexture = textureLoader.load('leaf_foreground_2.png')
    fgTexture.colorSpace = THREE.SRGBColorSpace
    bgTexture.colorSpace = THREE.SRGBColorSpace
    scene.background = bgTexture

    const fg_plane = new THREE.Mesh(
        new THREE.PlaneGeometry(8,4), 
        new THREE.MeshBasicMaterial({map:fgTexture, transparent: true})
    )

    const fg_plane_2 = new THREE.Mesh(
        new THREE.PlaneGeometry(8,4), 
        new THREE.MeshBasicMaterial({map:fgTexture, transparent: true})
    )

    const fg_plane_3 = new THREE.Mesh(
        new THREE.PlaneGeometry(8,4), 
        new THREE.MeshBasicMaterial({map:fgTexture, transparent: true})
    )

    fg_plane.translateY(3)
    fg_plane.translateZ(-3)

    fg_plane_2.translateY(2)
    fg_plane_2.translateZ(-3)
    fg_plane_2.translateX(-4)


    fg_plane_3.translateY(2)
    fg_plane_3.translateZ(-3)
    fg_plane_3.translateX(4)

    //
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 4);
    camera.position.z = 1;
    camera.position.y = 0.2
    //
    const renderer = new THREE.WebGLRenderer({canvas: canvas, antialias: true, alpha: true});
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setClearColor(0x000000, 0)
    renderer.autoClear = true;
    //

    //
    const scene_objs = []

    init_apples(scene)
    console.log(location.host)

    const light = new THREE.AmbientLight(new THREE.Color(1, 1, 1), .5)
    scene.add(light)
    const light2 = new THREE.PointLight(new THREE.Color(1, .9, 1), 50, 10)
    const light3 = new THREE.PointLight(new THREE.Color(1, 1, 1), 40, 1)

    light2.position.x = 2
    light2.position.y = 1
    light2.position.z = 1

    light3.position.y = -1
    light3.position.z = -1
    scene.add(light2)
    scene.add(light3)

    scene.add(fg_plane)
    scene.add(fg_plane_2)
    scene.add(fg_plane_3)

    scene_data = {renderer: renderer, 
        scene: scene, 
        camera: camera, 
        indicators: [],
        objs: scene_objs
    }

    //setupCamera(scene_camera)
}

export function init_indicators(){
    const circle_tex = new THREE.TextureLoader().load("circle.png");
    const circle_geometry = new THREE.PlaneGeometry(0.5, 0.5);
    const circle_material = new THREE.MeshBasicMaterial({ map: circle_tex, transparent: true, opacity: 1, color: new THREE.Color(1, 1, 1) });
    const circle_mesh_R = new THREE.Mesh(circle_geometry, circle_material);
    const circle_mesh_L = new THREE.Mesh(circle_geometry, circle_material);

    circle_mesh_L.position.x = 0.5
    circle_mesh_R.position.x = -0.5

    circle_mesh_L.position.z = .3
    circle_mesh_R.position.z = .3
/*
    scene_data.scene.add(circle_mesh_R);
    scene_data.scene.add(circle_mesh_L);

    scene_data.indicators = [circle_mesh_L, circle_mesh_R]
    */
}

function create_camera_plane(camera_canvas){
    video_plane = new THREE.PlaneGeometry(1.92, 1.08)
    video_texture = new THREE.CanvasTexture(camera_canvas)
    video_texture.colorSpace = THREE.SRGBColorSpace
    const video_mat = new THREE.MeshBasicMaterial({map:video_texture, transparent:true, side:THREE.DoubleSide})
    video = new THREE.Mesh(video_plane, video_mat)
    video.position.z = 0
    video.scale.x = -1.25
    video.scale.y = 1.25
    scene_data.scene.add(video)
}

export function init(window){
 init_physics()
 init_scene(window)   
 scene_data.renderer.setAnimationLoop(()=>{ animate()} );
}

export function init_camera(camera_canvas){
    create_camera_plane(camera_canvas)
}

var lastTime = 0;
var time = 0
var fixedTimeStep = 1.0 / 60.0; // seconds
var maxSubSteps = 3;

export function animate(){
    timer.update();
    var time_delta = timer.getDelta()
    if(video){
        video.material.map.needsUpdate = true
    }
    
    if(time != lastTime){
        delta_time = time-lastTime
    }

    if(scene_data.indicators.length > 0){
        scene_data.indicators[0].rotation.z += 0.0025
        scene_data.indicators[1].rotation.z += 0.0025
    }


    for(let i = 0; i < apple_array.length; i++){

        apple_physicsbodies[i].velocity.z = 0

        //connect apple mesh positions to physics positions
        apple_array[i].position.x = apple_physicsbodies[i].position.x
        apple_array[i].position.y = apple_physicsbodies[i].position.y
        apple_array[i].position.z = apple_physicsbodies[i].position.z
        apple_array[i].quaternion.x = apple_physicsbodies[i].quaternion.x;
        apple_array[i].quaternion.y = apple_physicsbodies[i].quaternion.y;
        apple_array[i].quaternion.z = apple_physicsbodies[i].quaternion.z;
        apple_array[i].quaternion.w = apple_physicsbodies[i].quaternion.w;
    }

    physics_world.step(fixedTimeStep, time_delta, maxSubSteps)

    scene_data.renderer.render(scene_data.scene, scene_data.camera)
}

export function onWindowResize(window) {
    scene_data.camera.aspect = window.innerWidth / window.innerHeight;
    scene_data.camera.updateProjectionMatrix();
    scene_data.renderer.setSize(window.innerWidth, window.innerHeight);
    //video_plane.scale.set(1.2 * window.innerWidth/window.innerHeight, 1.2, 1)
  }

export default init;