import React, {Component, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as GameGUI from "../Game/GameGUI.js";
import * as apple_game from '../Game/Apple.js';
import * as camera_display from '../Game/Camera.js';
import { getRepetitionCount, setOnRepetitionCallback } from '../Game/PoseDetection';

import "../Game/GameStyle.css"

const GameArea = ()=>{
    const canvasRef = useRef(null)
    const cameraRef = useRef(null)
    const navigate = useNavigate();
    let rep = 0
    let max_rep = 10
    let max_timer = 300

    /*
        SHOW INSTRUCTIONS
    */
    useEffect(()=>{
        if (cameraRef.current) {
            //initialize scene
            apple_game.init(window)
            apple_game.onWindowResize(window)
            window.addEventListener('resize', ()=>{apple_game.onWindowResize(window)});
            GameGUI.gui_set_callbacks(init_game, quit_game, pauseTimer, unpauseTimer)
            camera_display.init()

            setOnRepetitionCallback((count) => {
                // Update main HUD
                const rep_s = count.toString().padStart(2, '0');
                document.getElementById("goal-text").innerText = `${rep_s}x`;

                // Update pause menu if it's visible
                if (document.getElementById("pause-gui").style.display !== 'none') {
                    GameGUI.setSummaryText(rep_s, max_timer - timer);
                }

                // Check for game completion
                if (count >= max_rep) {
                    clearInterval(timer_interval);
                    GameGUI.gui_gameover(max_rep, max_timer);
                }
            });
        }
    })

    /*
        BEGIN GAME
    */
    const init_game = ()=>{
        //apple_game.init_camera(camera_canvas)
        //apple_game.init_indicators()
        //document.getElementById("test_button").style.visibility = "visible"
        startTimer(max_timer)
    }

    /*
        SPAWN APPLE TEST BUTTON FUNCTION
    */
    const spawn_apple = ()=>{
        apple_game.spawn_apple()
        increment_rep(1)
    }

    /*
        INCREMENT REP AND END GAME
    */

    function get_rep_string(number){
        return number.toString().padStart(2, '0');
    }

    const increment_rep = (amount)=>{
        rep += amount
        var rep_s = get_rep_string(rep)
        document.getElementById("goal-text").innerText = `${rep_s}x`

        if(rep >= max_rep){
            document.getElementById("test_button").style.visibility = "hidden"
            clearInterval(timer_interval)
            GameGUI.gui_gameover(max_rep, max_timer)
        }
    }

    /*
        TIMER 
    */
    var timer_interval
    var timer
    function startTimer(duration) {

        timer = duration;
        timer_interval = setInterval(function () {
            GameGUI.gui_update_timer(timer)

            if (--timer < 0) {
                onTimerEnd()
            }
        }, 1000);
    }
    function pauseTimer(){

        GameGUI.setSummaryText(get_rep_string(getRepetitionCount()), max_timer - timer)

        clearInterval(timer_interval)
    }
    function unpauseTimer(){
        startTimer(timer)
    }

    /*On exercise end (GAME COMPLETE) */

    function onTimerEnd(){
        clearInterval(timer_interval)
        GameGUI.show_gameover()
    }

    /*
        Quit game
    */

    function quit_game(){
        camera_display.close_stream();

        clearInterval(timer_interval);
        navigate("/medical-dashboard");
    }


    return(
        <>
            <canvas id="game-background" style={{ visibility: "hidden", background:"url('2d-background.png')", backgroundRepeat:"no-repeat", backgroundSize:"cover", width: '100%', height: '100%', backgroundColor: 'blue', position:'absolute', "zIndex":"-2"   }} />


            <canvas id="gamecanvas" ref={canvasRef} style={{ width: '50%', height: '50%', backgroundColor: 'clear', position:'absolute', "zIndex":"-1"   }} />
            <canvas id="camera_canvas" ref={cameraRef} style={{transform: "scale(-1, 1)", width:"100%",height:"100%", backgroundColor: 'clear', position:'absolute'}} />

            <canvas id="game-foreground" style={{ visibility :"hidden", background:"url('2d-foreground.png')", backgroundRepeat:"no-repeat", backgroundSize:"cover", width: '100%', height: '100%', backgroundColor: 'clear', position:'absolute', "zIndex":"-1"   }} />



            <GameGUI.GameHUD />
        </>
    )
}



export default GameArea