import React, { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation, Navigate } from "react-router-dom";
import MoveMendLoginPatientTable from "./pages/move-mend-login-patient-table";
import MedicalDashboard from "./pages/medical-dashboard";
import GameArea from "./pages/GameArea"
import { AuthProvider, useAuth } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";

function AppRoutes() {
  const { user, loading } = useAuth();
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    console.log("Pathname changed:", pathname);
    let title = "";
    let metaDescription = "";
    switch (pathname) {
      case "/":
        title = "Login - MoveMend";
        metaDescription = "Login to access the MoveMend application";
        break;
      case "/medical-dashboard":
        title = "Medical Dashboard - MoveMend";
        metaDescription = "View and manage medical data";
        break;
      case "/apple":
        title = "Games"
        metaDescription = "Games"
      default:
        title = "MoveMend";
        metaDescription = "MoveMend Application";
    }
    if (title) {
      document.title = title;
    }
    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
      <Routes>
        <Route path="/" element={<MoveMendLoginPatientTable />} />
        <Route
            path="/medical-dashboard"
            element={
              user ? <MedicalDashboard /> : <Navigate to="/" replace />
            }
        />
        <Route
          path ="/apple"
          element={
            <div>
            <GameArea />
            </div>
          }
          />
        {/* Other routes */}
      </Routes>
  );
}

function App() {
  return (
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
  );
}

export default App;
